import React from 'react';
import Link from 'next/link';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { navigationItemsFooter } from '@/constants/navigation';
import { clsx } from 'clsx';

export default function MenuSection() {
  return (
    <div className="flex lg:w-7/12 justify-between w-full gap-6 max-md:flex-col">
      {navigationItemsFooter.map((navItem) => {
        return navItem.dropdownItems ? (
          <div key={navItem.id} className="flex-col flex md:gap-6 gap-6">
            <Typography
              $type="PMedium"
              $color={COLORS.WHITE}
              $weight="700"
              className="font-jamjuree"
            >
              {navItem.title}
            </Typography>
            <div className="flex flex-col md:gap-6 gap-6">
              {navItem.dropdownItems.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={clsx(
                      item.comingSoon && 'opacity-60 pointer-events-none',
                      'flex items-center flex-wrap gap-2.5 max-md:ml-6',
                    )}
                  >
                    <Link
                      href={item.href}
                      className="!text-white h-fit underline-offset-1"
                    >
                      <Typography
                        $type="PMedium"
                        $weight="400"
                        className="font-jamjuree text-nowrap"
                        $color={COLORS.WHITE}
                      >
                        {item.title}
                      </Typography>
                    </Link>
                    {item.comingSoon && (
                      <div>
                        <Typography
                          $type="PMicro"
                          $fontSizeSm="11px"
                          $fontSizeXs="11px"
                          $color={COLORS.WHITE}
                          $weight="600"
                          className="opacity-60 bg-[#252A2B] rounded-full px-2 py-1s flex items-center justify-center"
                        >
                          soon
                        </Typography>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Link
            key={navItem.id}
            href={navItem.href}
            className="!text-white h-fit underline-offset-1"
          >
            <Typography
              $type="PMedium"
              $color={COLORS.WHITE}
              $weight="700"
              className="font-jamjuree text-nowrap"
            >
              {navItem.title}
            </Typography>
          </Link>
        );
      })}
    </div>
  );
}
