import React from 'react';
import { clsx } from 'clsx';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  padding?: string;
  id?: string;
};

export const Container = ({
  className,
  padding,
  children,
  id,
  ...rest
}: ContainerProps) => {
  return (
    <div
      {...rest}
      id={id}
      className={clsx(
        className,
        padding ? padding : 'md:px-17.5-vw px-4 py-0',
        'm-auto w-full',
      )}
    >
      {children}
    </div>
  );
};
