import React from 'react';

type Props = {
  isMobile?: boolean;
  className?: string;
  height?: string;
  width?: string;
};

export const MedalIcon = ({
  isMobile = false,
  height = '44',
  width = '28',
  className,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 28 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3223 1.08797C14.6162 0.30401 13.3838 0.30401 12.6777 1.08797C12.1405 1.68443 11.2683 1.84693 10.5512 1.48419C9.60868 1.00741 8.4595 1.45116 8.0852 2.43643C7.80042 3.18605 7.04607 3.6516 6.24591 3.57156C5.19423 3.46636 4.28347 4.29393 4.29152 5.34744C4.29765 6.14898 3.76297 6.85472 2.98784 7.06819C1.96904 7.34878 1.41971 8.44839 1.80904 9.42786C2.10524 10.1731 1.86244 11.0237 1.21702 11.5018C0.368708 12.1303 0.254996 13.3535 0.973004 14.1266C1.51929 14.7148 1.60115 15.5954 1.17261 16.2737C0.609354 17.1652 0.946617 18.3467 1.89634 18.8091C2.61892 19.1609 3.0144 19.9525 2.86061 20.7393C2.65848 21.7734 3.40117 22.7537 4.45434 22.8429C5.25563 22.9108 5.91131 23.5066 6.05305 24.2956C6.23934 25.3326 7.28715 25.9793 8.30154 25.6833C9.07331 25.458 9.90065 25.7775 10.3188 26.4622C10.8683 27.3621 12.0797 27.5879 12.9183 26.9466C13.5564 26.4587 14.4436 26.4587 15.0817 26.9466C15.9203 27.5879 17.1317 27.3621 17.6812 26.4622C18.0994 25.7775 18.9267 25.458 19.6985 25.6833C20.7128 25.9793 21.7607 25.3326 21.947 24.2956C22.0887 23.5066 22.7444 22.9108 23.5457 22.8429C24.5988 22.7537 25.3415 21.7734 25.1394 20.7393C24.9856 19.9525 25.3811 19.1609 26.1037 18.8091C27.0534 18.3467 27.3906 17.1652 26.8274 16.2737C26.3988 15.5954 26.4807 14.7148 27.027 14.1266C27.745 13.3535 27.6313 12.1303 26.783 11.5018C26.1376 11.0237 25.8948 10.1731 26.191 9.42786C26.5803 8.4484 26.031 7.34878 25.0122 7.06819C24.237 6.85472 23.7023 6.14898 23.7085 5.34744C23.7165 4.29393 22.8058 3.46636 21.7541 3.57156C20.9539 3.6516 20.1996 3.18605 19.9148 2.43643C19.5405 1.45116 18.3913 1.00741 17.4488 1.48419C16.7317 1.84693 15.8595 1.68443 15.3223 1.08797ZM13.9975 5.78613C9.44254 5.78613 5.75 9.46668 5.75 14.0069C5.75 18.5471 9.44254 22.2276 13.9975 22.2276C18.5525 22.2276 22.245 18.5471 22.245 14.0069C22.245 9.46668 18.5525 5.78613 13.9975 5.78613Z"
        fill={isMobile ? '#FFF' : '#51545B'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64844 25.3658C4.67547 25.4444 4.69722 25.5252 4.71331 25.6081C4.9308 26.7278 6.15405 27.4261 7.33828 27.1064C8.23928 26.8632 9.20514 27.2082 9.69327 27.9475C10.3348 28.9192 11.7491 29.1629 12.7281 28.4705C13.473 27.9437 14.5088 27.9437 15.2537 28.4705C16.2327 29.1629 17.6469 28.9192 18.2885 27.9475C18.7766 27.2082 19.7425 26.8632 20.6435 27.1064C21.8277 27.4261 23.051 26.7278 23.2685 25.6081C23.3004 25.4436 23.3547 25.2869 23.4283 25.1406V43.5005L14.3066 37.7973L4.64844 43.5005V25.3658Z"
        fill={isMobile ? '#FFF' : '#51545B'}
      />
      <ellipse
        cx="14"
        cy="13.9063"
        rx="6.75"
        ry="6.70318"
        fill={isMobile ? '#FFF' : '#51545B'}
      />
    </svg>
  );
};
