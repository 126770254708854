import React from 'react';
import { MediaRecognition } from '../MediaRecognition/MediaRecognition';
import { Container } from '@/components/Containers/Container';
import MenuSection from './MenuSection';
import DescriptionWithIconsSection from './DescriptionWithIconsSection';
import BottomSection from './BottomSection';

export default function Footer() {
  return (
    <>
      <footer className="containerFooter overflow-hidden relative z-10">
        <MediaRecognition isDark={true} />
        <Container padding="p-0">
          <div className="flex flex-col lg:gap-12 gap-8">
            <div className="flex gap-10 max-lg:flex-col">
              <MenuSection />
              <DescriptionWithIconsSection />
            </div>
            <BottomSection />
          </div>
        </Container>
      </footer>
    </>
  );
}
