import React from 'react';
import { AwardsComponent } from '@/components/AwardsComponent/AwardsComponent';
import { PatentSolution } from '@/components/PatentSolution/PatentSolution';

export default function AwardsSection() {
  return (
    <div className="flex lg:flex-col lg:w-5/12 flex-wrap gap-4">
      <div className="flex max-md:flex-wrap items-center gap-2.5">
        <img
          src="/img/footer-iso-icon.png"
          className="max-md:min-w-[120px] max-w-[120px] max-h-[58px] w-auto"
          height={58}
          alt="iso-icon"
        />
        <img
          src="/img/footer-gdpr-icon.png"
          className="max-md:min-w-[120px] max-w-[120px] max-h-[58px] w-auto"
          height={58}
          alt="gdpr-icon"
        />
        <div>
          <a
            href="https://www.aitechsuite.com/tools/20776?ref=featured&v=98"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <img
              src="/img/footer-aits-icon-simple.png"
              width={104}
              height={29}
            />
          </a>
        </div>
      </div>
      <div className="flex flex-wrap items-center md:gap-5 gap-4 opacity-70 h-auto 1xl:max-h-8">
        <PatentSolution
          typographyClassName="uppercase !text-xs"
          className="max-h-8"
        />
        <AwardsComponent className="!max-w-[250px] !max-h-[33px]" />
      </div>
    </div>
  );
}
