export const FreshdeskLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#25C16F" />
      <path
        d="M12.4625 5.56836C9.30117 5.56836 6.73828 8.13086 6.73828 11.2926V15.1848C6.76094 16.2266 7.6 17.0656 8.6418 17.0883H10.2609V12.6172H8.07344V11.3672C8.20625 9.01328 10.1543 7.17188 12.5125 7.17188C14.8707 7.17188 16.8141 9.00977 16.9469 11.3672V12.6172H14.7281V17.0914H16.191V17.1625C16.1754 18.1289 15.3961 18.9086 14.4332 18.9203H12.6859C12.5437 18.9203 12.3875 18.9914 12.3875 19.1332C12.3909 19.2112 12.4235 19.2852 12.4787 19.3404C12.534 19.3957 12.6079 19.4282 12.6859 19.4316H14.4437C15.6957 19.4238 16.7086 18.4109 16.7164 17.159V17.0168C17.1346 16.9226 17.5079 16.688 17.774 16.352C18.0402 16.016 18.1833 15.5989 18.1793 15.1703V11.3672C18.2504 8.14297 15.6934 5.57187 12.4551 5.57187L12.4625 5.56836Z"
        fill="white"
      />
    </svg>
  );
};
