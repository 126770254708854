import React, { memo } from 'react';
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import Link from 'next/link';
import {
  PATH_ARTICLES,
  PATH_CASE_STUDIES,
  PATH_DEMO,
} from '@/constants/spa-routes';
import style from './Header.module.css';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import Image from 'next/image';
import { clsx } from 'clsx';
import styled from 'styled-components';
import { NavigationItemsType } from '@/constants/navigation';

interface DesktopMenuProps {
  pathname: string;
  isIncludesRoutes: boolean;
  navigationItems: NavigationItemsType;
}

const StyledList = styled.li({
  ':hover': {
    '#menu-title': {
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
    },
  },
});

export const DesktopMenu = memo(
  ({ pathname, isIncludesRoutes, navigationItems }: DesktopMenuProps) => {
    return (
      <NavigationMenuList className="links lg:gap-[30px] gap-5">
        {navigationItems?.map((item) => {
          const dropdownLinksActive = item.dropdownItems?.some((el) =>
            isIncludesRoutes
              ? pathname.includes(el.href)
              : pathname === el.href,
          );
          return item.dropdownItems ? (
            <NavigationMenuItem
              key={`desktop-menu-dropdown-${item.id}-${item.title}`}
              className={style.desktopViewLi}
            >
              <NavigationMenuTrigger
                className={clsx(
                  dropdownLinksActive
                    ? '[&>div>p]:text-green-default [&>svg>path]:stroke-green-default'
                    : '',
                  'p-0 data-[active]:color-green-default text-white',
                )}
              >
                <div className="flex items-end">
                  {item?.isNew && (
                    <Typography
                      as={'span'}
                      $type="PSmall"
                      $fontSizeSm="12px"
                      $fontSizeXs="12px"
                      $color={dropdownLinksActive ? COLORS.WHITE : COLORS.GREEN}
                      $weight="500"
                      className="font-jamjuree pr-1"
                    >
                      new
                    </Typography>
                  )}

                  <Typography
                    $type="PSmall"
                    $fontSizeSm="14px"
                    $fontSizeXs="14px"
                    $color={COLORS.WHITE}
                    $weight={dropdownLinksActive ? '700' : '500'}
                    className="font-jamjuree"
                  >
                    {item.title}
                  </Typography>
                </div>
              </NavigationMenuTrigger>
              <NavigationMenuContent className="left-1/2 transform -translate-x-1/2">
                <ul className="flex gap-[clamp(20px,70px,70px)] z-50">
                  {item.dropdownItems.map((dropdownItem) => {
                    const listLinksActive = isIncludesRoutes
                      ? pathname.includes(dropdownItem.href)
                      : pathname === dropdownItem.href;
                    return (
                      <StyledList
                        key={`${dropdownItem.id}-${dropdownItem.title}`}
                        className={clsx(
                          'flex relative h-[135px] max-w-[200px] transition-all',
                          dropdownItem.comingSoon &&
                            'pointer-events-none opacity-60',
                        )}
                      >
                        <div
                          className={clsx(
                            listLinksActive &&
                              'h-[3px] w-full bg-green-default absolute -bottom-4',
                          )}
                        />
                        <NavigationMenuLink
                          className="h-full py-5"
                          asChild
                          aria-disabled={dropdownItem.comingSoon}
                        >
                          <Link
                            className="flex flex-col justify-between !no-underline"
                            href={dropdownItem.href}
                          >
                            <div className="flex flex-col gap-2">
                              <div className="flex items-center gap-2">
                                {dropdownItem.icon && (
                                  <div className="h-[25px] w-[25px] pointer-events-none">
                                    {typeof dropdownItem.icon === 'string' ? (
                                      <Image
                                        width="0"
                                        height="0"
                                        sizes="100vw"
                                        className={
                                          'w-full h-full object-contain'
                                        }
                                        alt={`${dropdownItem.title} image`}
                                        src={dropdownItem.icon}
                                      />
                                    ) : (
                                      dropdownItem.icon
                                    )}
                                  </div>
                                )}
                                <Typography
                                  id="menu-title"
                                  as={'span'}
                                  $type="PSmall"
                                  $fontSizeSm="14px"
                                  $fontSizeXs="14px"
                                  $color={COLORS.WHITE}
                                  $weight="500"
                                  className="text-nowrap font-jamjuree"
                                >
                                  {dropdownItem.title}
                                </Typography>
                              </div>
                              <Typography
                                $type="PMini"
                                $fontSizeSm="12px"
                                $fontSizeXs="12px"
                                $color={COLORS.WHITE}
                                $weight="400"
                                className="opacity-60 text-nowrap !no-underline font-jamjuree"
                              >
                                {dropdownItem.description}
                              </Typography>
                            </div>
                            {dropdownItem.comingSoon && (
                              <div>
                                <Typography
                                  $type="PMini"
                                  $fontSizeSm="11px"
                                  $fontSizeXs="11px"
                                  $color={COLORS.WHITE}
                                  $weight="600"
                                  className="opacity-60 uppercase bg-[#252A2B] rounded-full px-2 py-1 inline"
                                >
                                  soon
                                </Typography>
                              </div>
                            )}
                          </Link>
                        </NavigationMenuLink>
                      </StyledList>
                    );
                  })}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          ) : (
            <NavigationMenuItem
              key={`desktop-menu-${item.id}-${item.title}`}
              className={style.desktopViewLi}
            >
              <Link
                className={pathname === item.href ? 'selected' : ''}
                href={item.href}
              >
                {item.title}
              </Link>
            </NavigationMenuItem>
          );
        })}
        <NavigationMenuItem suppressHydrationWarning>
          <Link href={PATH_DEMO.ROOT}>
            <button className={`green-button ${style.headerDemoButton}`}>
              <div className="button-text">
                <span>Get Demo Access</span>
              </div>
              <div className="gradient"></div>
            </button>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    );
  },
);
