import React from 'react';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '../../constants/colors';
import { MedalIcon } from '../../assets/icons/medalIcon';
import { Tooltip } from 'react-tooltip';
import { clsx } from 'clsx';

type PatentSolutionProps = {
  className?: string;
  typographyClassName?: string;
};
export const PatentSolution = ({
  className,
  typographyClassName,
}: PatentSolutionProps) => {
  return (
    <div className={clsx(className, 'flex items-center gap-2 md:gap-2-vw')}>
      <MedalIcon
        isMobile
        className="md:max-h-[3.056vw] max-h-[44px] md:max-w-[1.944vw] max-w-[28px] opacity-45"
      />
      <Typography
        as="span"
        $type="PSmall"
        $color={COLORS.WHITE}
        $lineHeight={{ sm: '130%' }}
        className={clsx('opacity-45', typographyClassName)}
      >
        *
        <span
          className="underline underline-offset-2 cursor-pointer"
          data-tooltip-id="tooltip-patent"
          data-tooltip-place={'bottom'}
        >
          We patented our AI solution
        </span>{' '}
        <br className="max-sm:hidden" />
        as no one else does
      </Typography>
      <Tooltip
        id="tooltip-patent"
        className="max-w-64 z-20 text-left"
        content={
          'CoSupport AI uses the technologies for which the patent was obtained to create and train AI assistants to increase customer support efficiency.'
        }
        style={{
          backdropFilter: 'blur(20px)',
          background: '#0B0C1266',
          boxShadow: '0px 21px 40px 0px rgba(0, 0, 0, 0.15)',
          color: 'rgba(255, 255, 255, 0.80)',
          borderRadius: '10px',
          border: '1px solid rgba(255, 255, 255, 0.05)',
          opacity: '1',
        }}
      />
    </div>
  );
};
