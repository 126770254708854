import React, { cloneElement } from 'react';
import Link from 'next/link';
import { PATH_DEMO } from '@/constants/spa-routes';
import { Drawer } from 'vaul';
import { GreenButton } from '@/components/ButtonsCollection/GreenButton/GreenButton';
import { clsx } from 'clsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/mobileAccordion';
import Image from 'next/image';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { NavigationItemsType } from '@/constants/navigation';

interface MobileMenuProps {
  pathname: string;
  isIncludesRoutes: boolean;
  navigationItems: NavigationItemsType;
}

export const MobileMenu = ({
  pathname,
  isIncludesRoutes,
  navigationItems,
}: MobileMenuProps) => {
  return (
    <div>
      <ul className="flex flex-col gap-5">
        {navigationItems.map((item) => {
          const dropdownLinksActive = item.dropdownItems?.some((el) =>
            isIncludesRoutes
              ? pathname.includes(el.href)
              : pathname === el.href,
          );
          return item?.dropdownItems ? (
            <li key={item.id} className="text-center text-white w-full">
              <Accordion
                type="single"
                collapsible
                defaultValue={dropdownLinksActive ? item.title : ''}
                className="w-full"
              >
                <AccordionItem value={item.title} className="border-b-0">
                  <AccordionTrigger
                    className={clsx(
                      dropdownLinksActive && '[&>svg>path]:stroke-[3px]',
                      'p-0 w-full [&>svg_path]:stroke-white',
                    )}
                  >
                    <p
                      className={clsx(
                        dropdownLinksActive ? 'font-bold' : 'font-medium',
                        'text-[22px] font-jamjuree leading-[100%] text-white text-left flex items-center gap-2',
                      )}
                    >
                      {item.title}{' '}
                      {item?.isNew && (
                        <span className="text-green-default text-sm">new</span>
                      )}
                    </p>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col bg-[rgb(0_0_0_/_2%)] rounded">
                    {item?.dropdownItems.map((dropItem) => {
                      const listLinksActive = isIncludesRoutes
                        ? pathname.includes(dropItem.href)
                        : pathname === dropItem.href;
                      return (
                        <Drawer.Close
                          key={dropItem.id}
                          disabled={dropItem.comingSoon}
                          className={clsx(
                            dropItem.comingSoon &&
                              'pointer-events-none opacity-60',
                          )}
                        >
                          <Link
                            href={dropItem.href}
                            className="!no-underline flex items-center p-[20px_36px] gap-3.5"
                          >
                            <div className="h-[30px] w-[30px] flex items-center">
                              {typeof dropItem.icon === 'string' ? (
                                <Image
                                  width="0"
                                  height="0"
                                  sizes="100vw"
                                  className={'w-full h-full object-contain'}
                                  alt={`${dropItem.title} image`}
                                  src={dropItem.icon}
                                />
                              ) : (
                                dropItem.icon &&
                                cloneElement(dropItem.icon, {
                                  height: '30px',
                                  width: '30px',
                                })
                              )}
                            </div>
                            <p
                              className={clsx(
                                listLinksActive ? 'font-bold' : 'font-medium',
                                'text-[20px] font-jamjuree leading-[100%] text-white text-left',
                              )}
                            >
                              {dropItem.title}
                            </p>
                            {dropItem.comingSoon && (
                              <div>
                                <Typography
                                  $type="PMini"
                                  $fontSizeSm="11px"
                                  $fontSizeXs="11px"
                                  $color={COLORS.WHITE}
                                  $weight="600"
                                  className="opacity-60 uppercase bg-[#252A2B] rounded-full px-2 py-1 inline"
                                >
                                  soon
                                </Typography>
                              </div>
                            )}
                          </Link>
                        </Drawer.Close>
                      );
                    })}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </li>
          ) : (
            <Drawer.Close key={item.id} className="w-full">
              <li>
                <Link href={item.href} className="!no-underline">
                  <p
                    className={clsx(
                      isIncludesRoutes
                        ? pathname.includes(item.href)
                        : pathname === item.href
                          ? 'font-bold'
                          : 'font-medium',
                      'text-[22px] font-jamjuree leading-[100%] p-[15px_36px] text-white text-left',
                    )}
                  >
                    {item.title}
                  </p>
                </Link>
              </li>
            </Drawer.Close>
          );
        })}
        <li className="flex justify-center items-center">
          <GreenButton
            url={PATH_DEMO.ROOT}
            linkBtnClassName="w-full"
            className="w-full"
          >
            <span>Get Demo Access</span>
          </GreenButton>
        </li>
      </ul>
    </div>
  );
};
