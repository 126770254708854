export const AgentCoSupportIcon = ({
  height = '25',
  width = '25',
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.33333C0 1.49238 1.49238 0 3.33333 0H21.6667C23.5076 0 25 1.49238 25 3.33333V21.6667C25 23.5076 23.5076 25 21.6667 25H3.33333C1.49238 25 0 23.5076 0 21.6667V3.33333Z"
        fill="#75F3FB"
      />
      <path
        d="M9.46677 7.75876C8.21765 7.75876 6.9893 8.2585 6.10604 9.14804C5.22277 10.0376 4.72656 11.2441 4.72656 12.5021C4.72656 13.7601 5.22277 14.9665 6.10604 15.8561C6.9893 16.7456 8.21765 17.2454 9.46677 17.2454L9.46677 12.5021L9.46677 7.75876Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5175 17.2464C18.1355 17.2464 20.2578 15.1227 20.2578 12.5031C20.2578 9.88341 18.1355 7.75977 15.5175 7.75977C12.8996 7.75977 10.7773 9.88341 10.7773 12.5031C10.7773 15.1227 12.8996 17.2464 15.5175 17.2464ZM15.5056 14.4414C16.5766 14.4414 17.4448 13.5727 17.4448 12.501C17.4448 11.4293 16.5766 10.5605 15.5056 10.5605C14.4346 10.5605 13.5664 11.4293 13.5664 12.501C13.5664 13.5727 14.4346 14.4414 15.5056 14.4414Z"
        fill="#05050D"
      />
    </svg>
  );
};
