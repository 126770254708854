export const ResourcesArticleLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <mask
        id="mask0_13382_17171"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="14"
        height="13"
      >
        <path d="M6 6V17.8857V19H20V6H6Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13382_17171)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.821 1.17139C6.87331 1.17139 2.05176 5.99294 2.05176 11.9406V12.3142V12.6879C2.05176 18.6355 6.87331 23.4571 12.821 23.4571C18.7687 23.4571 23.5902 18.6355 23.5902 12.6879V12.321C23.5867 18.4799 18.7742 23.4571 12.821 23.4571C6.8656 23.4571 2.05176 18.4762 2.05176 12.3142C2.05176 6.15224 6.8656 1.17139 12.821 1.17139ZM23.5902 12.3075C23.5867 6.14862 18.7742 1.17139 12.821 1.17139C18.7687 1.17139 23.5902 5.99294 23.5902 11.9406V12.3075ZM16.5902 6.9991C16.461 6.87653 16.3102 6.82082 16.1595 6.82082C16.0087 6.82082 15.8579 6.87653 15.7502 6.9991L14.6733 8.11339L16.881 10.3977L17.9579 9.28339C18.2056 9.04939 18.2056 8.65939 17.9579 8.41424L16.5902 6.9991ZM16.2564 11.0551L14.0379 8.75967L7.51176 15.5122V17.8077H9.73022L16.2564 11.0551Z"
          fill="white"
          fillOpacity="0.4"
        />
      </g>
    </svg>
  );
};
