export const SlackLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#3F0F3F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 13.9569C9.5 13.1497 10.169 12.5 11 12.5C11.831 12.5 12.5 13.1497 12.5 13.9569V17.5431C12.5 18.3502 11.831 19 11 19C10.169 19 9.5 18.3502 9.5 17.5431V13.9569Z"
        fill="#E01E5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9569 15.5C14.1497 15.5 13.5 14.831 13.5 14C13.5 13.169 14.1497 12.5 14.9569 12.5H18.5431C19.3502 12.5 20 13.169 20 14C20 14.831 19.3502 15.5 18.5431 15.5H14.9569Z"
        fill="#ECB22D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 6.45687C13.5 5.64975 14.169 5 15 5C15.831 5 16.5 5.64975 16.5 6.45687V10.0431C16.5 10.8502 15.831 11.5 15 11.5C14.169 11.5 13.5 10.8502 13.5 10.0431V6.45687Z"
        fill="#2FB67C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45687 11.5C6.64975 11.5 6 10.831 6 9.99999C6 9.169 6.64975 8.5 7.45687 8.5H11.0431C11.8502 8.5 12.5 9.169 12.5 9.99999C12.5 10.831 11.8502 11.5 11.0431 11.5H7.45687Z"
        fill="#36C5F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 17.5C13.5 18.331 14.169 19 15 19C15.831 19 16.5 18.331 16.5 17.5C16.5 16.669 15.831 16 15 16H13.5V17.5Z"
        fill="#ECB22D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 11.5H17V9.99999C17 9.169 17.669 8.5 18.5 8.5C19.331 8.5 20 9.169 20 9.99999C20 10.831 19.331 11.5 18.5 11.5Z"
        fill="#2FB67C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49999 12.5H8.99998V14C8.99998 14.831 8.33099 15.5 7.49999 15.5C6.669 15.5 6 14.831 6 14C6 13.169 6.669 12.5 7.49999 12.5Z"
        fill="#E01E5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 6.49999V7.99998H11C10.169 7.99998 9.5 7.33099 9.5 6.49999C9.5 5.669 10.169 5 11 5C11.831 5 12.5 5.669 12.5 6.49999Z"
        fill="#36C5F1"
      />
    </svg>
  );
};
