export const AiBusinessIntelligence = ({
  height = '25',
  width = '25',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        d="M12.3374 5.58608C12.7158 5.25143 13.2842 5.25143 13.6626 5.58608L14.6323 6.44391C14.8219 6.61159 15.068 6.70116 15.321 6.69456L16.6152 6.66077C17.1202 6.64759 17.5556 7.013 17.6303 7.51254L17.8218 8.79302C17.8592 9.04332 17.9902 9.27012 18.1882 9.42768L19.2014 10.2337C19.5967 10.5482 19.6954 11.108 19.4315 11.5387L18.7551 12.6427C18.6229 12.8585 18.5774 13.1164 18.6279 13.3644L18.8859 14.6332C18.9866 15.1281 18.7023 15.6204 18.2233 15.7808L16.9956 16.1917C16.7556 16.272 16.5549 16.4403 16.4342 16.6627L15.8163 17.8005C15.5752 18.2444 15.041 18.4388 14.5711 18.2537L13.3664 17.7793C13.1309 17.6866 12.8691 17.6866 12.6336 17.7793L11.4289 18.2537C10.959 18.4388 10.4248 18.2444 10.1837 17.8005L9.56583 16.6627C9.44505 16.4403 9.24444 16.272 9.00444 16.1917L7.77667 15.7808C7.29769 15.6204 7.01345 15.1281 7.11411 14.6332L7.37213 13.3644C7.42256 13.1164 7.37709 12.8585 7.24487 12.6427L6.56847 11.5387C6.3046 11.108 6.40331 10.5482 6.79858 10.2337L7.81176 9.42768C8.00982 9.27012 8.14076 9.04332 8.17819 8.79302L8.36967 7.51254C8.44436 7.013 8.87984 6.64759 9.38476 6.66077L10.679 6.69456C10.932 6.70116 11.1781 6.61159 11.3677 6.44391L12.3374 5.58608Z"
        fill="#DFF800"
      />
      <path
        d="M12.9975 8.67722L14.1181 10.9147L16.3603 12.0337L14.1181 13.1526L12.9975 15.3901L11.8769 13.1526L9.63477 12.0337L11.8769 10.9147L12.9975 8.67722Z"
        fill="#05050D"
      />
    </svg>
  );
};
