import React, { useEffect, useState } from 'react';
import { Container } from '@/components/Containers/Container';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import { CloseIconSimple } from '@/assets/icons/closeIconSimple';
import { clsx } from 'clsx';
import Image from 'next/image';
import styled from 'styled-components';
import { PATH_ARTICLES } from '@/constants/spa-routes';
import Link from 'next/link';

const StyledWrapper = styled.div({
  cursor: 'pointer',
  'a:hover:not(:hover #close-icon-simple) p': {
    opacity: 1,
    span: {
      textDecoration: 'underline',
    },
    '#banner-span-green': {
      color: COLORS.GREEN,
    },
  },
});

type HeaderBannerProps = {
  disableClose?: boolean;
};

export const HeaderBanner = ({ disableClose = false }: HeaderBannerProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showBanner, setShowBanner] = useState(true);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(false);
    sessionStorage.setItem('header-banner-closed', 'true');
    document.body.classList.remove('header-banner', 'bg-ebony');
  };

  useEffect(() => {
    setShowBanner(
      Boolean(
        typeof window !== 'undefined' &&
          sessionStorage.getItem('header-banner-closed'),
      ),
    );

    return () => {
      document.body.classList.remove('header-banner', 'bg-ebony');
    };
  }, []);
  useEffect(() => {
    if (!showBanner && !disableClose) {
      document.body.classList.add('header-banner', 'bg-ebony');
    } else {
      document.body.classList.remove('header-banner');
    }
  }, [showBanner]);

  return isOpen && (disableClose || !showBanner) ? (
    <Container
      className={clsx(
        'bg-dark-green min-h-10 relative overflow-hidden flex items-center justify-center',
      )}
    >
      <StyledWrapper
        className={clsx(
          'relative w-full flex items-center justify-center max-md:pl-20',
          disableClose ? 'max-md:pr-20' : 'max-md:pr-8',
        )}
      >
        <Link
          href={PATH_ARTICLES.WORLD_AWARDS}
          className="relative w-full flex items-center justify-center transition"
        >
          <div className="relative flex flex-row-reverse gap-3 items-center">
            {disableClose ? (
              <div className="flex flex-col items-center md:py-3 py-2.5">
                <Typography
                  $weight="600"
                  $type="PMicro"
                  $fontSizeSm="0.563rem"
                  $fontSizeXs="0.563rem"
                  $color={COLORS.LIGHT}
                  className="font-formula md:text-center opacity-50 transition text-center"
                >
                  <span>AI World Series Winner 2024</span>
                </Typography>
                <Typography
                  $weight="700"
                  $type="PMicro"
                  $fontSizeSm="0.688rem"
                  $fontSizeXs="0.625rem"
                  $color={COLORS.LIGHT}
                  className="font-formula opacity-100 text-center"
                >
                  <span id="banner-span-green" className="transition">
                    Best Generative AI
                  </span>
                  <span> Product for text →</span>
                </Typography>
              </div>
            ) : (
              <Typography
                $weight="700"
                $type="PMicro"
                $fontSizeSm="0.688rem"
                $color={COLORS.LIGHT}
                className="font-formula md:py-3 py-2.5 md:text-center opacity-70 transition"
              >
                <span>AI World Series Winner 2024 - </span>
                <span id="banner-span-green" className="transition">
                  Best Generative AI
                </span>
                <span> Product for text</span>
              </Typography>
            )}
            <div className="md:w-[4.444vw] md:h-[5.347vw] absolute md:-left-20-vw top-0 -left-20 w-[60px] h-[72px]">
              <Image
                loading="lazy"
                fill
                objectFit="contain"
                alt="prize image"
                src="/img/prize.png"
              />
            </div>
            <div
              className={clsx(
                'md:w-[4.444vw] md:h-[5.347vw] w-[60px] h-[72px] absolute  md:-right-20-vw -right-16 -top-6 md:-top-12-vw',
                disableClose ? '' : 'max-md:hidden',
              )}
            >
              <Image
                loading="lazy"
                fill
                objectFit="contain"
                alt="prize image"
                src="/img/prize.png"
              />
            </div>
          </div>
        </Link>
        {!disableClose && (
          <div className="absolute right-0 h-full flex justify-center items-center cursor-default">
            <CloseIconSimple onClick={handleClose} strokeColor={COLORS.WHITE} />
          </div>
        )}
      </StyledWrapper>
    </Container>
  ) : null;
};
