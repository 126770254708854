export const ResourcesCaseStudiesLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        d="M11.4 14.4001L12.3333 13.4334L10.9 12.0001L12.3333 10.5667L11.4 9.60008L9 12.0001L11.4 14.4001ZM14.6 14.4001L17 12.0001L14.6 9.60008L13.6667 10.5667L15.1 12.0001L13.6667 13.4334L14.6 14.4001ZM7 18.0001V6.00008H11.1333C11.2778 5.60008 11.5196 5.27786 11.8587 5.03341C12.1978 4.78897 12.5782 4.66675 13 4.66675C13.4218 4.66675 13.8024 4.78897 14.142 5.03341C14.4816 5.27786 14.7231 5.60008 14.8667 6.00008H19V18.0001H7ZM8.33333 16.6667H17.6667V7.33341H8.33333V16.6667ZM13 6.83341C13.1444 6.83341 13.264 6.78608 13.3587 6.69141C13.4533 6.59675 13.5004 6.47741 13.5 6.33341C13.4996 6.18941 13.4522 6.07008 13.358 5.97541C13.2638 5.88075 13.1444 5.83341 13 5.83341C12.8556 5.83341 12.7362 5.88075 12.642 5.97541C12.5478 6.07008 12.5004 6.18941 12.5 6.33341C12.4996 6.47741 12.5469 6.59697 12.642 6.69208C12.7371 6.78719 12.8564 6.8343 13 6.83341Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};
