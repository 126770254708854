export const BiCoSupportIcon = ({
  height = '25',
  width = '25',
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_8846_8669"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.734375" width="24.0457" height="24" rx="3" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8846_8669)">
        <ellipse
          cx="13.1733"
          cy="12.003"
          rx="16.6811"
          ry="17.0909"
          fill="#DFF800"
        />
        <path
          d="M10.25 7.73003C9.14135 7.73003 8.05117 8.16843 7.26727 8.94879C6.48336 9.72915 6.04297 10.7875 6.04297 11.8911C6.04297 12.9947 6.48336 14.0531 7.26727 14.8335C8.05117 15.6138 9.14135 16.0522 10.25 16.0522L10.25 11.8911L10.25 7.73003Z"
          fill="#05050D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3089 16.0502C17.6175 16.0502 19.4889 14.1873 19.4889 11.8891C19.4889 9.59102 17.6175 7.72803 15.3089 7.72803C13.0004 7.72803 11.1289 9.59102 11.1289 11.8891C11.1289 14.1873 13.0004 16.0502 15.3089 16.0502ZM15.2938 13.5971C16.2393 13.5971 17.0057 12.8342 17.0057 11.893C17.0057 10.9519 16.2393 10.189 15.2938 10.189C14.3484 10.189 13.582 10.9519 13.582 11.893C13.582 12.8342 14.3484 13.5971 15.2938 13.5971Z"
          fill="#05050D"
        />
      </g>
    </svg>
  );
};
