export const CoSupportLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="19"
      viewBox="0 0 150 19"
      fill="none"
    >
      <path d="M8.74749 -0.000196847C6.44238 -0.000196744 4.17561 0.947857 2.54566 2.63541C0.9157 4.32295 -3.14225e-07 6.61176 -3.85907e-07 8.99831C-4.57589e-07 11.3849 0.915699 13.6737 2.54566 15.3612C4.17561 17.0488 6.44238 17.9968 8.74749 17.9968L8.74749 8.99831L8.74749 -0.000196847Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3086 18C24.1087 18 28 13.9712 28 9.00144C28 4.0317 24.1087 0.00292969 19.3086 0.00292969C14.5085 0.00292969 10.6172 4.0317 10.6172 9.00144C10.6172 13.9712 14.5085 18 19.3086 18ZM19.3059 12.6944C21.2717 12.6944 22.8653 11.0445 22.8653 9.00931C22.8653 6.97409 21.2717 5.32422 19.3059 5.32422C17.3402 5.32422 15.7466 6.97409 15.7466 9.00931C15.7466 11.0445 17.3402 12.6944 19.3059 12.6944Z"
      />
      <path
        d="M45.1295 7.76948C45.0639 6.88907 44.4567 5.99265 42.8977 5.99265C41.1582 5.99265 40.5018 7.00112 40.5018 8.48982V10.1226C40.5018 11.5953 41.1582 12.5878 42.9141 12.5878C44.5715 12.5878 45.0803 11.6913 45.1459 10.8109L45.1787 10.1226H47.6895L47.6731 10.6028C47.509 12.8439 46.0321 14.6527 42.8649 14.6527C39.5828 14.6527 37.9746 12.8279 37.9746 9.88248V8.66591C37.9746 5.75254 39.5828 3.92768 42.8649 3.92768C46.0156 3.92768 47.5254 5.73653 47.6731 7.97758L47.6895 8.48982H45.1787L45.1295 7.76948Z"
        fill="white"
      />
      <path
        d="M53.9322 14.6527C50.7158 14.6527 49.124 12.8279 49.124 9.88248V8.69792C49.124 5.75254 50.7158 3.92768 53.9322 3.92768C57.165 3.92768 58.7404 5.75254 58.7404 8.69792V9.88248C58.7404 12.8279 57.165 14.6527 53.9322 14.6527ZM51.6512 10.0906C51.6512 11.5953 52.2255 12.5878 53.9322 12.5878C55.6389 12.5878 56.2132 11.5953 56.2132 10.0906V8.48982C56.2132 7.00112 55.6389 5.99265 53.9322 5.99265C52.2255 5.99265 51.6512 7.00112 51.6512 8.48982V10.0906Z"
        fill="white"
      />
      <path
        d="M66.3522 8.82598C68.3543 9.38625 69.0107 10.2827 69.0107 11.6433C69.0107 13.3561 67.9605 14.6527 64.941 14.6527C61.544 14.6527 60.3297 13.2921 60.3297 11.1471V10.7789H61.8722V11.1311C61.8722 12.7638 62.8404 13.3881 64.9081 13.3881C66.6805 13.3881 67.4681 12.8119 67.4681 11.7394C67.4681 10.907 66.9922 10.3947 65.581 9.96252L63.2015 9.25819C61.3799 8.80997 60.6579 7.96158 60.6579 6.68097C60.6579 5.19227 61.7738 3.92768 64.5471 3.92768C67.6979 3.92768 68.8138 5.30433 68.8138 7.20922V7.49736H67.2548V7.20922C67.2548 5.72052 66.303 5.19227 64.5635 5.19227C63.2343 5.19227 62.2004 5.64048 62.2004 6.60093C62.2004 7.30527 62.5943 7.68945 63.7922 8.05762L66.3522 8.82598Z"
        fill="white"
      />
      <path
        d="M78.0052 14.4926V12.7638C77.4801 13.6923 76.4626 14.6527 74.6083 14.6527C72.3436 14.6527 71.0308 13.2281 71.0308 10.7469V4.08775H72.557V10.6188C72.557 12.4437 73.3939 13.3881 75.0677 13.3881C77.4965 13.3881 78.0052 11.4672 78.0052 10.4427V4.08775H79.5149V14.4926H78.0052Z"
        fill="white"
      />
      <path
        d="M83.7289 4.08775V5.68851C84.1391 4.95216 85.173 3.92768 87.0766 3.92768C89.6858 3.92768 91.2448 5.72052 91.2448 8.39378V10.2186C91.2448 12.9079 89.6694 14.6527 87.093 14.6527C85.1894 14.6527 84.1884 13.6603 83.7289 12.9559V18.1104H82.2356V4.08775H83.7289ZM83.7289 10.1546C83.7289 12.0275 84.7791 13.3721 86.7648 13.3721C88.7668 13.3721 89.7022 12.0115 89.7022 10.1386V8.4418C89.7022 6.58493 88.734 5.20828 86.7484 5.20828C84.812 5.20828 83.7289 6.5369 83.7289 8.42579V10.1546Z"
        fill="white"
      />
      <path
        d="M94.9626 4.08775V5.68851C95.3729 4.95216 96.4067 3.92768 98.3103 3.92768C100.92 3.92768 102.479 5.72052 102.479 8.39378V10.2186C102.479 12.9079 100.903 14.6527 98.3267 14.6527C96.4231 14.6527 95.4221 13.6603 94.9626 12.9559V18.1104H93.4693V4.08775H94.9626ZM94.9626 10.1546C94.9626 12.0275 96.0129 13.3721 97.9985 13.3721C100.001 13.3721 100.936 12.0115 100.936 10.1386V8.4418C100.936 6.58493 99.9677 5.20828 97.9821 5.20828C96.0457 5.20828 94.9626 6.5369 94.9626 8.42579V10.1546Z"
        fill="white"
      />
      <path
        d="M108.707 14.6527C105.72 14.6527 104.194 12.8919 104.194 9.99453V8.58587C104.194 5.68851 105.704 3.92768 108.707 3.92768C111.71 3.92768 113.22 5.68851 113.22 8.58587V9.99453C113.22 12.8919 111.71 14.6527 108.707 14.6527ZM105.737 10.0746C105.737 12.2356 106.705 13.3721 108.707 13.3721C110.709 13.3721 111.677 12.2356 111.677 10.0746V8.50583C111.677 6.34481 110.709 5.20828 108.707 5.20828C106.705 5.20828 105.737 6.34481 105.737 8.50583V10.0746Z"
        fill="white"
      />
      <path
        d="M116.966 4.08775V5.83257C117.376 4.92014 118.426 3.92768 120.067 3.92768C122.02 3.92768 123.005 5.11223 123.005 7.25724V8.18568H121.462V7.40131C121.462 6.1047 121.052 5.20828 119.608 5.20828C117.589 5.20828 116.966 7.08116 116.966 8.08964V14.4926H115.456V4.08775H116.966Z"
        fill="white"
      />
      <path
        d="M130.574 4.08775V5.36835H127.571V11.2591C127.571 12.8279 127.718 13.212 128.884 13.212H130.574V14.4926H128.473C126.406 14.4926 126.077 13.4522 126.077 11.5313V5.36835H123.714V4.08775H124.404C125.848 3.99171 126.389 2.9192 126.356 1.27043H127.866C127.866 2.82316 127.308 3.87965 126.586 4.08775H130.574Z"
        fill="white"
      />
      <path
        d="M133.386 14.6527C132.779 14.6527 132.287 14.2365 132.287 13.5962C132.287 12.9399 132.779 12.5077 133.386 12.5077C133.994 12.5077 134.486 12.9399 134.486 13.5962C134.486 14.2365 133.994 14.6527 133.386 14.6527Z"
        fill="white"
      />
      <path
        d="M143.222 14.4926V12.6838C142.484 14.0444 140.941 14.6527 139.267 14.6527C137.479 14.6527 136.067 13.9164 136.067 11.9314C136.067 10.1386 137.495 9.30621 139.251 8.874L140.515 8.56986C141.877 8.2177 143.206 7.86553 143.206 6.80903V6.745C143.206 5.96063 142.599 5.19227 140.777 5.19227C138.89 5.19227 137.905 5.99265 137.905 7.44933V7.83351H136.396V7.51336C136.396 5.52843 137.643 3.92768 140.744 3.92768C143.747 3.92768 144.716 5.30433 144.716 7.06515V14.4926H143.222ZM137.594 11.8034C137.594 12.9079 138.299 13.4041 139.661 13.4041C141.417 13.4041 143.025 12.3636 143.206 10.3947V8.4418C142.91 9.0821 141.909 9.38625 140.794 9.67438L139.71 9.96252C138.398 10.2987 137.594 10.7629 137.594 11.8034Z"
        fill="white"
      />
      <path
        d="M147.186 4.08775H148.679V14.4926H147.186V4.08775ZM146.874 2.00677C146.874 1.49453 147.252 1.11035 147.924 1.11035C148.597 1.11035 148.975 1.49453 148.975 2.00677C148.975 2.487 148.597 2.87118 147.924 2.87118C147.252 2.87118 146.874 2.487 146.874 2.00677Z"
        fill="white"
      />
    </svg>
  );
};
