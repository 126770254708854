export const ZendeskLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#03363D" />
      <path
        d="M11.9294 10.0362V18.4997H5L11.9294 10.0362ZM11.9294 7C11.9294 8.93419 10.3805 10.501 8.46841 10.501C6.55632 10.501 5 8.93419 5 7H11.9294ZM13.0708 18.5C13.0708 16.5658 14.6197 14.999 16.5318 14.999C18.4439 14.999 19.9928 16.5658 19.9928 18.5H13.0708ZM13.0708 15.4638V7H20L13.0708 15.4638Z"
        fill="white"
      />
    </svg>
  );
};
