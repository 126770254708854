import { clsx } from 'clsx';
import React from 'react';

type CloseIconProps = {
  strokeColor?: string;
  className?: string;
  id?: string;
  onClick?: (e: React.MouseEvent) => void;
};
export const CloseIconSimple = ({
  className,
  strokeColor = 'black',
  onClick = () => {},
  id = 'close-icon-simple',
  ...rest
}: CloseIconProps) => {
  return (
    <svg
      id={id}
      {...rest}
      onClick={onClick}
      className={clsx(className, 'hover:opacity-70 transition hover:rotate-90')}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="14.8497"
        y1="1.20711"
        x2="0.707548"
        y2="15.3492"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <line
        x1="14.8495"
        y1="15.3492"
        x2="0.7074"
        y2="1.20706"
        stroke={strokeColor}
        strokeWidth="2"
      />
    </svg>
  );
};
