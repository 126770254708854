export const SelfSufficientAi = ({
  height = '25',
  width = '25',
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8C20 6.89543 19.1046 6 18 6L8 6C6.89543 6 6 6.89543 6 8L6 15.1348C6 16.2394 6.89543 17.1348 8 17.1348L11.1595 17.1348L13.0654 19.0003L14.9714 17.1348L18 17.1348C19.1046 17.1348 20 16.2394 20 15.1348L20 8Z"
        fill="#A498FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4184 12.0999C17.7811 11.7615 17.7811 11.213 17.4184 10.8746C17.0556 10.5363 16.4674 10.5363 16.1047 10.8746C15.7419 11.213 15.7419 11.7615 16.1047 12.0999C16.4674 12.4382 17.0556 12.4382 17.4184 12.0999ZM13.5722 10.8747C13.9349 11.2131 13.9349 11.7619 13.5722 12.1003C13.2095 12.4387 12.6215 12.4387 12.2589 12.1003C11.8962 11.7619 11.8962 11.2131 12.2589 10.8747C12.6216 10.5362 13.2095 10.5362 13.5722 10.8747ZM9.93684 10.8744C10.2995 11.2129 10.2995 11.7616 9.93684 12.1001C9.57418 12.4385 8.9862 12.4385 8.62354 12.1001C8.26088 11.7616 8.26088 11.2129 8.62354 10.8744C8.9862 10.536 9.57418 10.536 9.93684 10.8744Z"
        fill="#05050D"
      />
    </svg>
  );
};
