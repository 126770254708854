import React from 'react';
import Link from 'next/link';
import { PATH_PRIVACY } from '@/constants/spa-routes';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/constants/colors';
import AwardsSection from '@/components/Footer/AwardsSection';

export default function BottomSection() {
  return (
    <div className="flex relative gap-10 max-lg:flex-col">
      <div className="flex max-lg:flex-col lg:gap-10 gap-8 max-lg:w-full lg:w-7/12">
        <div className="flex w-full flex-col md:gap-3.5 gap-4">
          <Typography
            $type="PSmall"
            $weight="500"
            className="font-jamjuree"
            $color={COLORS.WHITE}
          >
            <span className="opacity-30">
              <br /> ‘CoSupport’ is a trademark of NLP Lab Team in the US and
              other countries. 5356 Hermitage Ave., Valley Village, CA, 91607,
              US.
            </span>{' '}
            <Typography
              $type="PSmall"
              $weight="500"
              className="font-jamjuree email-address"
              $color={COLORS.WHITE}
              as={'a'}
              href="mailto:contact@cosupports.ai"
            >
              contact@cosupports.ai
            </Typography>
          </Typography>
          <div className="privacy">
            <div className="flex md:gap-5 gap-5 flex-wrap">
              <Link
                href={PATH_PRIVACY.TERMS_OF_WEBSITE}
                className="underline !text-white h-fit underline-offset-1 opacity-30 hover:opacity-100 transition"
              >
                <Typography
                  $type="PSmall"
                  $color={COLORS.WHITE}
                  $weight="500"
                  className="font-jamjuree"
                >
                  Terms of Website
                </Typography>
              </Link>
              <Link
                href={PATH_PRIVACY.TERMS_OF_SERVICES}
                className="underline !text-white h-fit underline-offset-1 opacity-30 hover:opacity-100 transition"
              >
                <Typography
                  $type="PSmall"
                  $color={COLORS.WHITE}
                  $weight="500"
                  className="font-jamjuree"
                >
                  Terms of Services
                </Typography>
              </Link>
              <Link
                href={PATH_PRIVACY.ROOT}
                className="underline !text-white h-fit underline-offset-1 opacity-30 hover:opacity-100 transition"
              >
                <Typography
                  $type="PSmall"
                  $color={COLORS.WHITE}
                  $weight="500"
                  className="font-jamjuree"
                >
                  Privacy and Cookies Policy
                </Typography>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:gap-5 gap-5 lg:w-3/6">
          <Typography
            $type="PSmall"
            $weight="500"
            $color={COLORS.WHITE}
            className="font-jamjuree"
          >
            CoFollow us to stay in the loop
          </Typography>
          <div className="flex w-full justify-between social-icons">
            <a
              className="linkedin"
              href="https://www.linkedin.com/company/98213887"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <g clipPath="url(#clip0_2838_147012)">
                  <rect width="21" height="21" fill="none" />
                  <path
                    d="M4.40714 2.22321C4.40684 2.81255 4.1744 3.37763 3.76095 3.79415C3.34749 4.21066 2.78689 4.44449 2.20247 4.4442C1.61804 4.4439 1.05767 4.20951 0.644631 3.79257C0.231589 3.37564 -0.000291935 2.81033 2.75844e-07 2.22099C0.000292487 1.63165 0.232734 1.06657 0.646189 0.650052C1.05965 0.233536 1.62025 -0.00029439 2.20467 2.78164e-07C2.78909 0.000294947 3.34946 0.234691 3.7625 0.651623C4.17555 1.06856 4.40743 1.63387 4.40714 2.22321ZM4.47324 6.08966H0.0661072V20H4.47324V6.08966ZM11.4365 6.08966H7.05142V20H11.3924V12.7004C11.3924 8.63397 16.648 8.25621 16.648 12.7004V20H21V11.1894C21 4.3342 13.2214 4.58975 11.3924 7.95623L11.4365 6.08966Z"
                    fill="#F1F7F6"
                    stroke="#05050D"
                    strokeWidth="0.2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2838_147012">
                    <rect width="21" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              className="instagram"
              href="https://www.instagram.com/cosupport.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="21"
                viewBox="0 0 19 21"
                fill="none"
              >
                <g clipPath="url(#clip0_2838_146756)">
                  <rect width="19" height="21" fill="#05050D" />
                  <mask
                    id="mask0_2838_146756"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="1"
                    width="19"
                    height="19"
                  >
                    <path
                      d="M14.7778 1H4.22222C3.10242 1 2.02848 1.44484 1.23666 2.23666C0.44484 3.02848 0 4.10242 0 5.22222V15.7778C0 16.8976 0.44484 17.9715 1.23666 18.7633C2.02848 19.5552 3.10242 20 4.22222 20H14.7778C15.8976 20 16.9715 19.5552 17.7633 18.7633C18.5552 17.9715 19 16.8976 19 15.7778V5.22222C19 4.10242 18.5552 3.02848 17.7633 2.23666C16.9715 1.44484 15.8976 1 14.7778 1Z"
                      fill="white"
                    />
                    <path
                      d="M9.49908 14.7222C10.6189 14.7222 11.6928 14.2774 12.4846 13.4856C13.2765 12.6937 13.7213 11.6198 13.7213 10.5C13.7213 9.3802 13.2765 8.30627 12.4846 7.51445C11.6928 6.72263 10.6189 6.27779 9.49908 6.27779C8.37927 6.27779 7.30533 6.72263 6.51351 7.51445C5.7217 8.30627 5.27686 9.3802 5.27686 10.5C5.27686 11.6198 5.7217 12.6937 6.51351 13.4856C7.30533 14.2774 8.37927 14.7222 9.49908 14.7222Z"
                      fill="black"
                    />
                    <path
                      d="M15.3056 5.75004C15.5855 5.75004 15.854 5.63883 16.0519 5.44088C16.2499 5.24292 16.3611 4.97444 16.3611 4.69449C16.3611 4.41454 16.2499 4.14605 16.0519 3.9481C15.854 3.75014 15.5855 3.63893 15.3056 3.63893C15.0256 3.63893 14.7571 3.75014 14.5592 3.9481C14.3612 4.14605 14.25 4.41454 14.25 4.69449C14.25 4.97444 14.3612 5.24292 14.5592 5.44088C14.7571 5.63883 15.0256 5.75004 15.3056 5.75004Z"
                      fill="black"
                    />
                  </mask>
                  <g mask="url(#mask0_2838_146756)">
                    <path
                      d="M-3.16797 -2.16661H22.1654V23.1667H-3.16797V-2.16661Z"
                      fill="#F1F7F6"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2838_146756">
                    <rect width="19" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              className="twitter"
              href="https://twitter.com/cosupportai"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="21"
                viewBox="0 0 18 21"
                fill="none"
              >
                <g clipPath="url(#clip0_2838_146515)">
                  <rect width="18" height="21" fill="#05050D" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.21354 2L0 2L6.53275 11.5523L0.041493 19.2344L3.10148 19.2344L7.90153 13.5538L11.7865 19.2344L18 19.2344L11.3357 9.48967L17.6643 2L14.6043 2L9.96687 7.48821L6.21354 2ZM8.01023 9.80381L4.18235 4.2066L5.04934 4.2066L8.48939 9.23673L8.01023 9.80381ZM12.9507 17.0278L9.37901 11.8053L9.85818 11.2382L13.8177 17.0278L12.9507 17.0278Z"
                    fill="#F1F7F6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2838_146515">
                    <rect width="18" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              className="youtube"
              href="https://www.youtube.com/channel/UC9RNK2lsGajtGYx-cNfFxKw"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="21"
                viewBox="0 0 23 21"
                fill="none"
              >
                <g clipPath="url(#clip0_2838_146289)">
                  <rect width="23" height="21" fill="#05050D" />
                  <path
                    d="M11.5252 1.49989C12.126 1.50326 13.6291 1.51789 15.2267 1.58202L15.7937 1.60677C17.4015 1.68215 19.0081 1.81265 19.8057 2.03429C20.8689 2.33356 21.7037 3.20437 21.9861 4.30919C22.4361 6.0643 22.4924 9.48676 22.4992 10.3159L22.5003 10.4869V10.6827C22.4924 11.5119 22.4361 14.9355 21.9861 16.6895C21.7004 17.7977 20.8644 18.6696 19.8057 18.9644C19.0081 19.186 17.4015 19.3165 15.7937 19.3919L15.2267 19.4178C13.6291 19.4808 12.126 19.4965 11.5252 19.4988L11.2608 19.4999H10.9739C9.70261 19.492 4.38552 19.4346 2.69342 18.9644C1.63135 18.6651 0.795425 17.7943 0.513032 16.6895C0.063004 14.9343 0.00675042 11.5119 0 10.6827V10.3159C0.00675042 9.48676 0.063004 6.06317 0.513032 4.30919C0.7988 3.20099 1.63473 2.32906 2.69454 2.03542C4.38552 1.56401 9.70373 1.50664 10.9751 1.49989H11.5252ZM8.99944 5.5627V15.4382L15.7499 10.5004L8.99944 5.5627Z"
                    fill="#F1F7F6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2838_146289">
                    <rect width="23" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              className="facebook"
              href="https://www.facebook.com/CoSupportAI"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="21"
                viewBox="0 0 12 21"
                fill="none"
              >
                <g clipPath="url(#clip0_2838_146078)">
                  <rect width="12" height="21" fill="#05050D" />
                  <path
                    d="M10.0322 3.55172C8.63649 3.55172 8.23973 4.16772 8.23973 5.52572L8.23973 7.76711L11.952 7.76711L11.5861 11.398L8.23903 11.398L8.26646 20.9999L3.79801 20.9999L3.7966 11.3973L0.799807 11.3973L0.799806 7.76641L3.79801 7.76641L3.79801 5.58802C3.798 1.92492 5.27389 -7.70762e-05 9.41382 -7.78001e-05C10.3023 -7.79554e-05 11.3653 0.0699218 11.9998 0.158122L11.9998 3.56712"
                    fill="#F1F7F6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2838_146078">
                    <rect width="12" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <Typography
            $type="PSmall"
            $weight="500"
            className="font-jamjuree opacity-30"
            $color={COLORS.WHITE}
          >
            © {new Date().getFullYear()} CoSupport Team. All rights reserved.
          </Typography>
        </div>
      </div>
      <AwardsSection />
    </div>
  );
}
