export const ResourcesFAQLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0002 18.6666C9.31816 18.6666 6.3335 15.6819 6.3335 11.9999C6.3335 8.31792 9.31816 5.33325 13.0002 5.33325C16.6822 5.33325 19.6668 8.31792 19.6668 11.9999C19.6668 15.6819 16.6822 18.6666 13.0002 18.6666ZM13.0002 17.3333C14.4147 17.3333 15.7712 16.7713 16.7714 15.7712C17.7716 14.771 18.3335 13.4144 18.3335 11.9999C18.3335 10.5854 17.7716 9.22888 16.7714 8.22868C15.7712 7.22849 14.4147 6.66659 13.0002 6.66659C11.5857 6.66659 10.2291 7.22849 9.22893 8.22868C8.22873 9.22888 7.66683 10.5854 7.66683 11.9999C7.66683 13.4144 8.22873 14.771 9.22893 15.7712C10.2291 16.7713 11.5857 17.3333 13.0002 17.3333ZM12.3335 14.6666H13.6668V15.9999H12.3335V14.6666ZM12.3335 13.3386C12.3335 13.3386 13.6668 13.3333 13.6668 13.3386C13.6668 12.6706 15.6668 11.9999 15.6668 10.6666C15.6668 9.19325 14.4848 7.99992 13.0062 7.99992C12.6555 7.99913 12.3081 8.06752 11.9838 8.20118C11.6596 8.33484 11.3649 8.53114 11.1167 8.77884C10.8684 9.02654 10.6714 9.32078 10.5371 9.64471C10.4027 9.96864 10.3335 10.3159 10.3335 10.6666H11.6668C11.6668 9.93325 12.2668 9.33325 13.0002 9.33325C13.7335 9.33325 14.3335 9.93325 14.3335 10.6666C14.3335 11.2666 12.3335 12.2446 12.3335 13.3386Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};
