import style from './MediaRecognition.module.css';
import React, { useEffect, useState } from 'react';
import {
  ApSliderIcon,
  AsiaOneSliderIcon,
  BarChartSliderIcon,
  BenzingaSliderIcon,
  BusinessInsiderSliderIcon,
  FoxSliderIcon,
  MinyanvilleSliderIcon,
  NewsMaxSliderIcon,
  SISliderIcon,
  TheGlobeAndMailSliderIcon,
  YahooSliderIcon,
} from './MediaRecognitionIcons';
import { useDeviceSelectors } from 'react-device-detect';
import { clsx } from 'clsx';

type MediaRecognitionProps = {
  isDark: boolean;
  className?: string;
};

export const MediaRecognition = ({
  isDark,
  className = '',
}: MediaRecognitionProps) => {
  const [mobile, setMobile] = useState();

  useEffect(() => {
    const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);
    setMobile(selectors.isMobile);
  }, []);

  return (
    <div
      className={clsx(
        'w-screen min-h-20 lg:m-[0_calc(-1*var(--left-right-padding))] lg:p-[0_var(--left-right-padding)] p-0 m-[0_calc(-1*var(--left-right-padding-mobile))-2px]',
        mobile ? style.mobile : style.desktop,
        isDark && style.dark,
        className,
      )}
    >
      <div
        className={clsx(
          style.container,
          'lg:flex-row flex-col md:gap-6 gap-4 lg:pt-6 lg:pb-6 lg:px-[4vw] pt-6 pb-0 px-0',
        )}
      >
        <div className={style.titleContainer}>
          {mobile ? (
            <p>CoSupport AI Media Recognition:</p>
          ) : (
            <>
              <p>CoSupport AI</p>
              <p>Media Recognition:</p>
            </>
          )}
        </div>
        <div className={clsx(style.sliderContainer, 'lg:w-4/5 w-full')}>
          <div className={style.sliderLeftShadow}></div>
          <div className={style.sliderRightShadow}></div>
          <div className={style.slider}>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://pr.newsmax.com/article/Cosupport-Ai-Achieved-the-Patent-for-Its-Customer-Support-Solutions-That-Innovate-Business-Operations?storyId=65c779f07ff9c30008b621ca'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <NewsMaxSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://markets.businessinsider.com/news/stocks/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations-1033077939'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <BusinessInsiderSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.benzinga.com/pressreleases/24/02/37194501/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operatio'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <BenzingaSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={'https://www.wicz.com/story/50436615/story?S=50436615'}
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <FoxSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.barchart.com/story/news/24163367/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <BarChartSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://apnews.com/press-release/marketersmedia/california-intellectual-property-0c8e5398238a723b6725acdf938631be'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <ApSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.asiaone.com/business/cosupport-ai-achieved-patent-its-customer-support-solutions-innovate-business-operations-0'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <AsiaOneSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.theglobeandmail.com/investing/markets/markets-news/Plentisoft/23985691/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations/'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <TheGlobeAndMailSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://finance.yahoo.com/news/cosupport-ai-achieved-patent-customer-063900113.html'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <YahooSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.streetinsider.com/Press+Releases/CoSupport+AI+Achieved+the+Patent+for+Its+Customer+Support+Solutions+That+Innovate+Business+Operations/22796463.html'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <SISliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://finance.minyanville.com/minyanville/news/article/marketersmedia-2024-2-18-cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <MinyanvilleSliderIcon />
              </a>
            </div>

            <div className={style.sliterItem}>
              <a
                href={
                  'https://pr.newsmax.com/article/Cosupport-Ai-Achieved-the-Patent-for-Its-Customer-Support-Solutions-That-Innovate-Business-Operations?storyId=65c779f07ff9c30008b621ca'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <NewsMaxSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://markets.businessinsider.com/news/stocks/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations-1033077939'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <BusinessInsiderSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.benzinga.com/pressreleases/24/02/37194501/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operatio'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <BenzingaSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={'https://www.wicz.com/story/50436615/story?S=50436615'}
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <FoxSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.barchart.com/story/news/24163367/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <BarChartSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://apnews.com/press-release/marketersmedia/california-intellectual-property-0c8e5398238a723b6725acdf938631be'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <ApSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.asiaone.com/business/cosupport-ai-achieved-patent-its-customer-support-solutions-innovate-business-operations-0'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <AsiaOneSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.theglobeandmail.com/investing/markets/markets-news/Plentisoft/23985691/cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations/'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <TheGlobeAndMailSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://finance.yahoo.com/news/cosupport-ai-achieved-patent-customer-063900113.html'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <YahooSliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://www.streetinsider.com/Press+Releases/CoSupport+AI+Achieved+the+Patent+for+Its+Customer+Support+Solutions+That+Innovate+Business+Operations/22796463.html'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <SISliderIcon />
              </a>
            </div>
            <div className={style.sliterItem}>
              <a
                href={
                  'https://finance.minyanville.com/minyanville/news/article/marketersmedia-2024-2-18-cosupport-ai-achieved-the-patent-for-its-customer-support-solutions-that-innovate-business-operations'
                }
                target={'_blank'}
                rel="noreferrer noopener nofollow"
              >
                <MinyanvilleSliderIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      {!isDark && (
        <img
          className="absolute top-0 left-0 z-0 h-full w-full max-lg:hidden"
          src={'/img/zendesk-integration/media-recognition-desktop.png'}
          width={'100%'}
        />
      )}
    </div>
  );
};
