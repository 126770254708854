export const AgentAiAssistant = ({
  height = '25',
  width = '25',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        d="M17.7417 14.8V9.74192C17.7417 7.12303 15.6187 5 12.9998 5V5C10.3809 5 8.25785 7.12303 8.25785 9.74192V16.1682C8.25785 16.959 8.8989 17.6001 9.68968 17.6001H11.5566"
        stroke="#75F3FB"
        strokeWidth="1.5"
      />
      <path
        d="M7.76172 10.1864C7.04833 10.3021 6.50366 10.9209 6.50366 11.667V12.8004C6.50366 13.5464 7.04833 14.1653 7.76172 14.2809V10.1864Z"
        fill="#75F3FB"
        stroke="#75F3FB"
      />
      <path
        d="M18.2417 14.2814C18.9551 14.1657 19.4998 13.5468 19.4998 12.8008L19.4998 11.6674C19.4998 10.9214 18.9551 10.3025 18.2417 10.1868L18.2417 14.2814Z"
        fill="#75F3FB"
        stroke="#75F3FB"
      />
      <path
        d="M11.47 17.6C11.47 18.1126 11.8681 18.5 12.3249 18.5C12.7816 18.5 13.1797 18.1126 13.1797 17.6C13.1797 17.0873 12.7816 16.7 12.3249 16.7C11.8681 16.7 11.47 17.0873 11.47 17.6Z"
        fill="#75F3FB"
        stroke="#75F3FB"
      />
    </svg>
  );
};
